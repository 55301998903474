export const constInsuranceOptions = [
  {
    id: '365-30',
    name: 'V1',
    benefitDurationDays: 365,
    waitingPeriodDays: 30,
    price: 175,
    standard: true,
    archived: false,
  },
  {
    id: '365-60',
    name: 'V2',
    benefitDurationDays: 365,
    waitingPeriodDays: 60,
    price: 125,
    standard: false,
    archived: false,
  },
  {
    id: '730-30',
    name: 'V3',
    benefitDurationDays: 730,
    waitingPeriodDays: 30,
    price: 350,
    standard: false,
    archived: false,
  },
  {
    id: '730-60',
    name: 'V4',
    benefitDurationDays: 730,
    waitingPeriodDays: 60,
    price: 250,
    standard: false,
    archived: false,
  },
] as const
