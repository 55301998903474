import { sum } from 'lodash'
import { categoryByIdRequired, requiresInsuranceOptionByCategory } from 'shared/data/categories-service'
import {
  ApprovedLicense,
  BookingRelevantTransactionPure,
  LicenseBooking,
  LicenseBookingWithCategoryLineItem,
} from 'shared/db/db'
import {
  totalOpenRemainingBalance,
  transactionsByLineItemType,
  transactionsByType,
  transactionsTotal,
} from 'shared/db/transactions-service'
import { t } from 'shared/i18n/current'
import { todoMigrateAssociation } from 'shared/models/associations'
import { associationSpecificDetails, CategoryType } from 'shared/models/category'
import { standardInsuranceOptionPrice } from 'shared/models/insurance-options'
import { groupByLiteral } from 'shared/utils/array'
import { strictEntries } from 'shared/utils/object'

export function allLicenseBookingKPIsByCategoryType(
  transactions: BookingRelevantTransactionPure[]
): LicenseBookingKPIsByCategoryType[] {
  const categoryLineItems = transactionsByLineItemType(transactions).categoryLineItems
  const byCategoryType = groupByLiteral(
    categoryLineItems,
    (lineItem) => categoryByIdRequired(lineItem.item.categoryId).type
  )
  return strictEntries(byCategoryType).flatMap(([categoryType, lineItems]) =>
    strictEntries(allLicenseBookingKPIs(lineItems)).map(([kpi, value]) => ({ categoryType, kpi, value }))
  )
}

interface LicenseBookingKPIsByCategoryType {
  kpi: KPI
  categoryType: CategoryType
  value: number
}

export function allTransactionKPIs(transactions: BookingRelevantTransactionPure[]): KPIs {
  const byType = transactionsByType(transactions)

  const totalBalances = transactionsTotal(transactions)

  const totalInscriptionBookings = transactionsTotal(byType.inscriptionBookings)
  const openInscriptionBookings = totalOpenRemainingBalance(byType.inscriptionBookings)

  const openLicenseBookings = totalOpenRemainingBalance(byType.licenseBookings)

  return {
    totalBalances,
    totalBalanceWithouLicenseBookings: totalBalances - openLicenseBookings,

    totalAutomaticPayments: transactionsTotal(byType.automaticPayments),
    totalManualPayments: transactionsTotal(byType.manualPayments),

    totalInscriptionBookings,
    openInscriptionBookings,
    paidInscriptionBookings: totalInscriptionBookings - openInscriptionBookings,

    ...allLicenseBookingKPIs(transactions),

    totalManualBookings: transactionsTotal(byType.manualBookings),
  }
}

export function allLicenseBookingKPIs(transactions: BookingRelevantTransactionPure[]): KPIs {
  const byType = transactionsByType(transactions)

  const totalLicenseBookings = transactionsTotal(byType.licenseBookings)
  const openLicenseBookings = totalOpenRemainingBalance(byType.licenseBookings)

  const { categoryLineItems, transponderLineItems } = transactionsByLineItemType(byType.licenseBookings)

  const totalTransponderBookings = transactionsTotal(transponderLineItems)
  const openTransponderBookings = totalOpenRemainingBalance(transponderLineItems)

  const totalCategoryBookings = transactionsTotal(categoryLineItems)
  const openCategoryBookings = totalOpenRemainingBalance(categoryLineItems)

  const withInsuranceInfo = bookingsWithInsuranceInfo(categoryLineItems)
  const withInsurance = withInsuranceInfo.filter(({ fullLicense }) => fullLicense)

  const secondaryLicenses = withInsuranceInfo.filter(({ fullLicense }) => !fullLicense)

  const totalCategoryBookingsWithInsurance = transactionsTotal(
    withInsurance.map(({ booking }) => booking)
  )
  const openCategoryBookingsWithInsurance = totalOpenRemainingBalance(
    withInsurance.map(({ booking }) => booking)
  )

  const withInsuranceOpen = withInsurance.filter(({ booking }) => licenseBookingOpen(booking))

  const totalAmountCategoryBookingsWithInsurance = sum(withInsurance.map(({ countAs }) => countAs))
  const openAmountCategoryBookingsWithInsurance = sum(withInsuranceOpen.map(({ countAs }) => countAs))

  const secondaryLicensesOpen = secondaryLicenses.filter(({ booking }) => licenseBookingOpen(booking))

  const totalAmountCategoryBookingsSecondary = sum(secondaryLicenses.map(({ countAs }) => countAs))
  const openAmountCategoryBookingsSecondary = sum(secondaryLicensesOpen.map(({ countAs }) => countAs))

  const totalInsuranceCategoryBookingsWithInsurance = sum(
    withInsurance.map(({ priceInsuranceBookkeeping }) => priceInsuranceBookkeeping)
  )
  const openInsuranceCategoryBookingsWithInsurance = sum(
    withInsuranceOpen.map(({ priceInsuranceBookkeeping }) => priceInsuranceBookkeeping)
  )

  return {
    totalLicenseBookings,
    openLicenseBookings,
    paidLicenseBookings: totalLicenseBookings - openLicenseBookings,

    totalTransponderBookings,
    openTransponderBookings,
    paidTransponderBookings: totalTransponderBookings - openTransponderBookings,

    totalCategoryBookings,
    openCategoryBookings,
    paidCategoryBookings: totalCategoryBookings - openCategoryBookings,

    totalCategoryBookingsWithInsurance,
    openCategoryBookingsWithInsurance,
    paidCategoryBookingsWithInsurance:
      totalCategoryBookingsWithInsurance - openCategoryBookingsWithInsurance,

    totalAmountCategoryBookingsWithInsurance,
    openAmountCategoryBookingsWithInsurance,
    paidAmountCategoryBookingsWithInsurance:
      totalAmountCategoryBookingsWithInsurance - openAmountCategoryBookingsWithInsurance,

    totalAmountCategoryBookingsSecondary,
    openAmountCategoryBookingsSecondary,
    paidAmountCategoryBookingsSecondary:
      totalAmountCategoryBookingsSecondary - openAmountCategoryBookingsSecondary,

    totalInsuranceCategoryBookingsWithInsurance,
    openInsuranceCategoryBookingsWithInsurance,
    paidInsuranceCategoryBookingsWithInsurance:
      totalInsuranceCategoryBookingsWithInsurance - openInsuranceCategoryBookingsWithInsurance,
  }
}

export function licenseBookingOpen(booking: LicenseBookingWithCategoryLineItem) {
  return booking.remainingBalance !== 0 && booking.item.price >= 0
}

interface LicenseBookingWithInsuranceInfo {
  booking: LicenseBookingWithCategoryLineItem
  fullLicense: boolean
  priceInsuranceBookkeeping: number
  countAs: number
}

export function bookingsWithInsuranceInfo(
  bookings: LicenseBookingWithCategoryLineItem[]
): LicenseBookingWithInsuranceInfo[] {
  return bookings.map((booking) => {
    const association = todoMigrateAssociation(booking.item.association)
    const category = categoryByIdRequired(booking.item.categoryId)
    const lineItemPrice = Math.abs(booking.item.price)
    const categoryDetails = associationSpecificDetails(category, association)
    const mainLicense = booking.item.subtype === 'mainLicense'
    const fullLicense = mainLicense && lineItemPrice !== 0
    const positivePriceOrZero = booking.item.price >= 0
    const countAs = positivePriceOrZero ? 1 : -1

    const insuranceOptionRequired = requiresInsuranceOptionByCategory(
      categoryDetails.association,
      category.year,
      categoryDetails
    )

    const insurancePrice = insuranceOptionRequired
      ? booking.item.insuranceOptionPrice ?? standardInsuranceOptionPrice()
      : categoryDetails.priceInsuranceBookkeeping
    const priceInsuranceBookkeeping = insurancePrice * countAs

    return { booking, fullLicense, priceInsuranceBookkeeping, countAs }
  })
}

export function isMainLicenseByLicenseBookings(license: ApprovedLicense, bookings: LicenseBooking[]) {
  const filteredBookings = bookings.filter(
    (booking) =>
      booking.item.type === 'categoryLineItem' &&
      booking.item.subtype === 'mainLicense' &&
      booking.item.association === license.licenseAssociation &&
      booking.item.categoryId === license.categoryId
  )

  const amount = sum(filteredBookings.map((booking) => (booking.item.reverse ? -1 : 1)))
  return amount > 0
}

export type KPIs = Partial<Record<KPI, number>>

export type KPI = keyof ReturnType<typeof t>['kpiTitles']
