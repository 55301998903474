import { constCategories } from 'shared/data/categories'
import { constCategories2021 } from 'shared/data/categories-2021'
import { constCategories2022 } from 'shared/data/categories-2022'
import { constCategories2023 } from 'shared/data/categories-2023'
import { constCategories2024 } from 'shared/data/categories-2024'
import { Year } from 'shared/data/license-year'
import { LicenseType } from 'shared/db/license-type'
import { AssociationID } from 'shared/models/associations'
import { InsuranceOptionId } from 'shared/models/insurance-options'
import { TransponderType } from 'shared/models/transponder-type'
import { ArrayElement } from 'shared/utils/tsc'

export type CategoryOfAssociation = Category & AssociationCategory

export interface Category {
  readonly id: CategoryId
  readonly commonName: string
  readonly startListName: string
  readonly associations: Readonly<AssociationID[]>
  readonly shortId: number
  readonly active: boolean
  readonly archived: boolean
  readonly sidecar: boolean
  readonly sidecarPassenger: boolean
  readonly type: CategoryType
  readonly typeName: string
  readonly transponders: Readonly<TransponderType[]>
  readonly numberChoice: boolean
  readonly importName: string
  readonly year: Year
  readonly associationSpecificDetails: Readonly<AssociationCategory[]>
  readonly categoryType: 'licenseCategory'
  readonly enlistWithoutLicense: boolean
  readonly groups: CategoryGroupDefinition
}

export interface AssociationCategory {
  readonly association: AssociationID
  readonly prices: Readonly<PriceByAgeAndAssociation[]>
  readonly priceHint: string
  readonly priceAdditionalLicense: number
  readonly preferAsAdditionalLicense: boolean
  readonly priceInsuranceBookkeeping: number
  readonly priceInscriptionWithLicense: number
  readonly discountInscriptionWithLicenseTwoDays: number
  readonly priceInscriptionPower: number
  readonly priceInscriptionSurcharge: number
  readonly priceInscriptionHoursBeforeEvent: number
  readonly surcharge: number
  readonly surchargeAfter: string
  readonly discount: number
  readonly discountUntil: string
  readonly hint: string
  readonly advertiseSamInsurance: boolean
  readonly needsComment: string
  readonly offersNationalLicense: boolean
  readonly offersInternationalLicense: boolean
  readonly needsBikeInfo: boolean
  readonly needsHealthCheck: Readonly<BooleanByAgeAndAssociation[]>
  readonly needsInsurance: boolean
  readonly needsEmergency: boolean
  readonly showAdditionalInsuranceForm: boolean
  readonly oneEventLicense: boolean
  readonly name: string
  readonly shortName: string
  readonly myLapsName: string
  readonly startListName: string
  readonly myLapsSpecialExportFormatting: boolean
  readonly colorBackground: string
  readonly colorForeground: string
  readonly colorLicense: string
  readonly numberFixed: boolean
  readonly needsMembership: boolean

  readonly priceDayLicenseForMember: number
  readonly priceDayLicenseForMemberTwoDays: number
  readonly priceDayLicenseWithoutMember: number
  readonly priceDayLicenseWithoutMemberTwoDays: number
  readonly priceDayLicenseWithOtherLicense: number | undefined
  readonly priceDayLicenseWithOtherLicenseTwoDays: number | undefined
}

export interface MergedUntranslatedCategory {
  readonly id: CategoryId
  readonly commonNameDe: string
  readonly commonNameFr: string
  readonly commonNameEn: string
  readonly startListName: string
  readonly associations: Readonly<AssociationID[]>
  readonly shortId: number
  readonly active: boolean
  readonly archived: boolean
  readonly sidecar: boolean
  readonly sidecarPassenger: boolean
  readonly type: CategoryType
  readonly transponders: Readonly<TransponderType[]>
  readonly numberChoice: boolean
  readonly importName: string
  readonly year: Year
  readonly associationSpecificDetails: Readonly<UntranslatedAssociationSpecificCategoryDetails[]>
  readonly categoryType: 'licenseCategory'
  readonly enlistWithoutLicense: boolean
  readonly groups: CategoryGroupDefinition
}

export interface UntranslatedAssociationSpecificCategoryDetails {
  readonly association: AssociationID
  readonly prices: Readonly<PriceByAgeAndAssociation[]>
  readonly priceHintDe: string
  readonly priceHintEn: string
  readonly priceHintFr: string
  readonly priceAdditionalLicense: number
  readonly preferAsAdditionalLicense: boolean
  readonly priceInsuranceBookkeeping: number
  readonly priceInscriptionWithLicense: number
  readonly discountInscriptionWithLicenseTwoDays: number
  readonly priceInscriptionPower: number

  readonly priceInscriptionSurcharge: number
  readonly priceInscriptionHoursBeforeEvent: number
  readonly surcharge: number
  readonly surchargeAfter: string
  readonly discount: number
  readonly discountUntil: string
  readonly hintDe: string
  readonly hintEn: string
  readonly hintFr: string
  readonly advertiseSamInsurance: boolean
  readonly needsCommentDe: string
  readonly needsCommentFr: string
  readonly needsCommentEn: string
  readonly offersNationalLicense: boolean
  readonly offersInternationalLicense: boolean
  readonly needsBikeInfo: boolean
  readonly needsHealthCheck: Readonly<BooleanByAgeAndAssociation[]>
  readonly needsInsurance: boolean
  readonly needsEmergency: boolean
  readonly showAdditionalInsuranceForm: boolean
  readonly oneEventLicense: boolean
  readonly nameDe: string
  readonly nameEn: string
  readonly nameFr: string
  readonly shortNameDe: string
  readonly shortNameEn: string
  readonly shortNameFr: string
  readonly myLapsName: string
  readonly startListName: string
  readonly myLapsSpecialExportFormatting: boolean
  readonly colorBackground: string
  readonly colorForeground: string
  readonly colorLicense: string
  readonly numberFixed: boolean
  readonly needsMembership: boolean

  readonly priceDayLicenseForMember: number
  readonly priceDayLicenseForMemberTwoDays: number
  readonly priceDayLicenseWithoutMember: number
  readonly priceDayLicenseWithoutMemberTwoDays: number
  readonly priceDayLicenseWithOtherLicense: number | undefined
  readonly priceDayLicenseWithOtherLicenseTwoDays: number | undefined
}

export interface SingleUntranslatedCategory {
  readonly id: CategoryId
  readonly association: AssociationID
  readonly active: boolean
  readonly archived: boolean
  readonly offersNationalLicense: boolean
  readonly offersInternationalLicense: boolean
  readonly needsBikeInfo: boolean
  readonly needsHealthCheck: Readonly<BooleanByAgeAndAssociation[]>
  readonly needsInsurance: boolean
  readonly needsEmergency: boolean
  readonly showAdditionalInsuranceForm: boolean
  readonly oneEventLicense: boolean
  readonly groups: CategoryGroupDefinition
  readonly sidecar: boolean
  readonly sidecarPassenger: boolean
  readonly type: CategoryType
  readonly commonNameDe: string
  readonly commonNameFr: string
  readonly commonNameEn: string
  readonly nameDe: string
  readonly nameFr: string
  readonly nameEn: string
  readonly importName: string
  readonly myLapsName: string
  readonly startListName: string
  readonly myLapsSpecialExportFormatting: boolean
  readonly shortNameDe: string
  readonly shortNameFr: string
  readonly shortNameEn: string
  readonly prices: Readonly<PriceByAgeAndAssociation[]>
  readonly priceHintDe: string
  readonly priceHintFr: string
  readonly priceHintEn: string
  readonly priceAdditionalLicense: number
  readonly preferAsAdditionalLicense: boolean
  readonly enlistWithoutLicense: boolean
  readonly priceDayLicenseForMember: number
  readonly priceDayLicenseForMemberTwoDays: number
  readonly priceDayLicenseWithoutMember: number
  readonly priceDayLicenseWithoutMemberTwoDays: number
  readonly priceDayLicenseWithOtherLicense: number | undefined
  readonly priceDayLicenseWithOtherLicenseTwoDays: number | undefined
  readonly surcharge: number
  readonly surchargeAfter: string
  readonly discount: number
  readonly discountUntil: string
  readonly priceInsuranceBookkeeping: number
  readonly priceInscriptionWithLicense: number
  readonly discountInscriptionWithLicenseTwoDays: number
  readonly priceInscriptionPower: number
  readonly priceInscriptionSurcharge: number
  readonly priceInscriptionHoursBeforeEvent: number
  readonly transponders: Readonly<TransponderType[]>
  readonly hintDe: string
  readonly hintFr: string
  readonly hintEn: string
  readonly colorBackground: string
  readonly colorForeground: string
  readonly colorLicense: string
  readonly numberFixed: boolean
  readonly year: Year
  readonly advertiseSamInsurance: boolean
  readonly numberChoice: boolean
  readonly needsCommentDe: string
  readonly needsCommentFr: string
  readonly needsCommentEn: string
  readonly needsMembership: boolean
}

export type CategoryGroupDefinition = CategoryGroupDefinitionEnabled | CategoryGroupDefinitionDisabled

export interface CategoryGroupDefinitionDisabled {
  enabled: false
  defaultMaxGroups: 1
}

export interface CategoryGroupDefinitionEnabled {
  enabled: true
  defaultMaxGroups: number
  defaultMaxParticipantsPerGroup: number
}

export interface PriceByAgePure {
  from?: string | undefined
  to?: string | undefined
  value: number
}

export interface PriceByAge {
  from?: string | undefined
  to?: string | undefined
  value: number
  licenseType: LicenseType
}

export interface PriceByAgeAndAssociation {
  from?: string | undefined
  to?: string | undefined
  value: number
  association: AssociationID
  licenseType: LicenseType
}

export interface BooleanByAgePure {
  from?: string | undefined
  to?: string | undefined
  value: boolean
}

export interface BooleanByAge {
  from?: string | undefined
  to?: string | undefined
  value: boolean
  licenseType: LicenseType
}

export interface BooleanByAgeAndAssociation {
  from?: string | undefined
  to?: string | undefined
  value: boolean
  association: AssociationID
  licenseType: LicenseType
}

export interface PriceContext {
  birthdate: string
  association: AssociationID
  licenseType: LicenseType
  insuranceOption?: InsuranceOptionId | undefined
}

export interface ByAgePure<T> {
  from?: string | undefined
  to?: string | undefined
  value: T
}

export interface StringByAgePure {
  from?: string | undefined
  to?: string | undefined
  value: string
}

export type CategoryType = ArrayElement<typeof categoryTypes>

export const categoryTypes = [
  'motocross',
  'mofacup',
  'mofacross',
  'pitbikecross',
  'minibike',
  'trial',
  'supermoto',
  'roadRacing',
  'iceRace',
  'snx',
  'dragster',
  'vintage',
  'enduro',
  'enduroCrossCountry',
  'bergrennen',
  'track',
  'supercross',
  'otherFms',
  'eMoto',
  'other',
  'omc',
] as const

export function isCategoryType(type: string): type is CategoryType {
  return categoryTypes.includes(type as any)
}

export function associationSpecificDetails(
  category: { associationSpecificDetails: Category['associationSpecificDetails'] },
  association: AssociationID
) {
  const details = category.associationSpecificDetails.find(
    (details) => details.association === association
  )
  if (!details)
    throw new Error(
      `Association specific details for category ${JSON.stringify(
        category
      )} and association ${JSON.stringify(association)} not found`
    )
  return details
}

export const categoryIds = [
  ...constCategories2021.map((category) => category.id),
  ...constCategories2022.map((category) => category.id),
  ...constCategories2023.map((category) => category.id),
  ...constCategories2024.map((category) => category.id),
  ...constCategories.map((category) => category.id),
]

export type CategoryId = ArrayElement<typeof categoryIds>
