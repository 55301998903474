import { fixedLicenseType } from 'shared/data/categories-service'
import { registeredViaTrustedAssociation } from 'shared/db/association'
import { ApprovedLicense, LicenseDrafts, LicenseDraftWithDocuments } from 'shared/db/db'
import { LicenseType } from 'shared/db/license-type'
import { AssociationID, todoMigrateAssociation } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'
import { InsuranceOptionId } from 'shared/models/insurance-options'
import { truthy } from 'shared/utils/array'
import { DateString } from 'shared/utils/date'

export function licenseCategoryContexts(
  licenseDrafts: LicenseDrafts | undefined,
  approvedLicenses: ApprovedLicense[]
): LicenseCategoryContext[] {
  const categories = licenseDrafts?.categories || []
  return categories
    .map((category) => {
      const details = fixedLicenseType(licenseDrafts?.categoryDetails?.[category])
      if (!details) return undefined
      return {
        association: details.licenseAssociation,
        category,
        licenseType: details.licenseType,
        insuranceOption: details.insuranceOption,
        draftProcessedAt: details.processedAt,
        approvedAt: approvedLicenses.find((license) => license.categoryId === category)?.approvedAt,
      }
    })
    .filter(truthy)
}

export function licenseCategoryContextsFromLicenses(
  licenseDrafts: LicenseDraftWithDocuments[]
): LicenseCategoryContext[] {
  return licenseDrafts
    .map((license) =>
      license.type !== 'approved' || registeredViaTrustedAssociation(license.approved)
        ? undefined
        : {
            association: todoMigrateAssociation(license.approved.licenseAssociation),
            category: license.approved.categoryId,
            licenseType: license.approved.licenseType || 'national',
            insuranceOption: license.approved.insuranceOption,
            draftProcessedAt: license.approved.draftProcessedAt,
            approvedAt: license.type === 'approved' ? license.approved.approvedAt : undefined,
          }
    )
    .filter(truthy)
}

export interface LicenseCategoryContext {
  association: AssociationID
  category: CategoryId
  licenseType: LicenseType
  insuranceOption?: InsuranceOptionId | undefined
  draftProcessedAt: string | undefined
  approvedAt: DateString | undefined
}
