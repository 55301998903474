import assertNever from 'assert-never'
import { groupBy, partition, sortBy, uniq } from 'lodash'
import {
  categoryByIdRequired,
  categoryCommonName,
  fixedLicenseType,
} from 'shared/data/categories-service'
import { displayIssuedNumber } from 'shared/data/licenses-service'
import {
  ApprovedLicense,
  Documents,
  DocumentsWithLicenseContexts,
  DocumentsWithUid,
  LicenseDrafts,
  LicenseDraftWithDocuments,
  LicenseWithContext,
} from 'shared/db/db'
import { queryParts, licenseDraftFoundByQueryParts } from 'shared/db/search'
import { licenseTasksForLicense } from 'shared/license/license-tasks'
import { todoMigrateAssociation } from 'shared/models/associations'
import { CategoryId } from 'shared/models/category'
import { reverseFullName } from 'shared/models/personal-data'
import { truthy } from 'shared/utils/array'

export function documentsWithLicenses(data: {
  documents: Record<string, DocumentsWithUid>
  drafts: Record<string, LicenseDrafts>
  approved: Record<string, ApprovedLicense[]>
}): DocumentsWithLicenseContexts[] {
  return Object.values(data.documents)
    .map((documents) => {
      if (!documents?.personalData) return undefined

      const uid = documents.uid
      const draft: LicenseDrafts | undefined = data.drafts[uid]
      const licensedFromDrafts = (draft?.categories || []).map((categoryId) =>
        combineDocumentsAndDrafts(categoryId, uid, draft, data.documents)
      )
      const licensedFromApproved = (data.approved[uid] || []).map((approved) =>
        combineDocumentsAndApproved(approved.categoryId, uid, draft, data.documents, approved)
      )
      const combined = [...licensedFromDrafts, ...licensedFromApproved]

      const byUid = groupBy(combined, (license) => license.userId)
      const byNumberAndCategoryType = groupBy(combined, (license) => numberAndCategoryType(license))

      const licensesWithContext = combined.map<LicenseWithContext>((license) => ({
        license,
        tasks: licenseTasksForLicense(license, license.documents.personalData?.birthdate || ''),
        all: calculateAllLicensesOfSameRider(license, byUid),
        others: calculateOtherLicenses(license, byUid),
        conflicts: calculateNumberConflicts(license, byNumberAndCategoryType),
        approved: data.approved[license.userId] || [],
      }))

      return { uid, licensesWithContext, documents }
    })
    .filter(truthy)
}

export function categoriesByUser(data: {
  drafts: Record<string, LicenseDrafts>
  documents: Record<string, Documents>
  approved: Record<string, ApprovedLicense[]>
}) {
  const { drafts, documents, approved } = data
  const combined = Object.entries(drafts).map(([userId, draft]) => ({
    id: userId,
    userId,
    categoryIds: [
      ...(draft?.categories || []),
      ...(approved[userId]?.map(({ categoryId }) => categoryId) || []),
    ],
    documents: documents[userId],
    remarks: draft.summary?.remarks || '',
  }))
  return sortDraftsByUser(combined)
}

function sortDraftsByUser<T extends { documents: Documents }>(licenses: T[]): T[] {
  return sortBy(licenses, (license) => reverseFullName(license.documents.personalData))
}

export function licenseDraftsByCategory(
  data: {
    drafts: Record<string, LicenseDrafts>
    documents: Record<string, Documents>
    approved: Record<string, ApprovedLicense[]>
  },
  query: string,
  // TODO: later: calculateFieldsAllOthersAndConflicts was passed to improve the performance of this function. this is fixed and should be built back, as this variable is now unused. but not now.
  _calculateFieldsAllOthersAndConflicts: boolean
): LicenseWithContext[] {
  const uids = uniq([...Object.keys(data.drafts), ...Object.keys(data.approved)])
  const combined = uids.flatMap((userId) => {
    const draft: LicenseDrafts | undefined = data.drafts[userId]
    const licensedFromDrafts = (draft?.categories || []).map((categoryId) =>
      combineDocumentsAndDrafts(categoryId, userId, draft, data.documents)
    )
    const licensedFromApproved = (data.approved[userId] || []).map((approved) =>
      combineDocumentsAndApproved(approved.categoryId, userId, draft, data.documents, approved)
    )
    return [...licensedFromDrafts, ...licensedFromApproved]
  })

  const parts = queryParts(query)
  const [partsStatus, partsLicense] = partition(parts, (part) => licenseTaskFilters().has(part.original))
  const filteredDrafts = combined.filter((draft) =>
    licenseDraftFoundByQueryParts(draft, data.approved[draft.userId] || [], partsLicense)
  )

  const sortedDrafts = sortDraftsByCategory(filteredDrafts)

  const byUid = groupBy(combined, (license) => license.userId)
  const byNumberAndCategoryType = groupBy(combined, (license) => numberAndCategoryType(license))

  sortedDrafts.map((license) =>
    licenseTasksForLicense(license, license.documents.personalData?.birthdate || '')
  )

  const mapped = sortedDrafts.map<LicenseWithContext>((license) => ({
    license,
    tasks: licenseTasksForLicense(license, license.documents.personalData?.birthdate || ''),
    all: calculateAllLicensesOfSameRider(license, byUid),
    others: calculateOtherLicenses(license, byUid),
    conflicts: calculateNumberConflicts(license, byNumberAndCategoryType),
    approved: data.approved[license.userId] || [],
  }))

  if (partsStatus.length > 1) return []

  const status = licenseTaskFilters().get(partsStatus[0]?.original) || 'all'

  return mapped.filter(
    (license) =>
      status === 'all' ||
      (status === 'allVerified'
        ? license.tasks.allVerified
        : status === 'allDone'
        ? !license.tasks.allVerified && license.tasks.allDone
        : status === 'openTasks'
        ? !license.tasks.allVerified && !license.tasks.allDone
        : assertNever(status))
  )
}

function numberAndCategoryType(license: LicenseDraftWithDocuments): string {
  return `${license.draft.categoryDetails?.preferredNumber}-${license.draft.category?.type}`
}

export function licenseTaskFilters() {
  return new Map<string, 'openTasks' | 'allVerified' | 'allDone'>([
    ['Rot', 'openTasks'],
    ['Grün', 'allVerified'],
    ['Weiss', 'allDone'],
  ])
}

function calculateOtherLicenses(
  current: LicenseDraftWithDocuments,
  lookup: Record<string, LicenseDraftWithDocuments[]>
) {
  return calculateAllLicensesOfSameRider(current, lookup).filter((other) => current.id !== other.id)
}

function calculateAllLicensesOfSameRider(
  current: LicenseDraftWithDocuments,
  lookup: Record<string, LicenseDraftWithDocuments[]>
) {
  return lookup[current.userId] || []
}

function calculateNumberConflicts(
  current: LicenseDraftWithDocuments,
  lookup: Record<string, LicenseDraftWithDocuments[]>
) {
  return (lookup[numberAndCategoryType(current)] || []).filter((other) => current.id !== other.id)
}

function combineDocumentsAndDrafts(
  categoryId: CategoryId,
  userId: string,
  draft: LicenseDrafts | undefined,
  documents: Record<string, Documents>
): LicenseDraftWithDocuments {
  const category = categoryByIdRequired(categoryId)
  const categoryDetails = fixedLicenseType(draft?.categoryDetails?.[categoryId])
  return {
    id: `${userId}-${categoryId}-draft`,
    userId,
    draft: {
      categoryId,
      category,
      categoryDetails,
      summary: draft?.summary,
    },
    documents: documents?.[userId] || {},
    type: 'draft',
    association: categoryDetails?.licenseAssociation || category.associations[0],
  }
}

function combineDocumentsAndApproved(
  categoryId: CategoryId,
  userId: string,
  draft: LicenseDrafts | undefined,
  documents: Record<string, Documents>,
  approved: ApprovedLicense
): LicenseDraftWithDocuments {
  const category = categoryByIdRequired(categoryId)
  const approvedDetails = draft?.approvedCategoryDetails?.[categoryId]
  const insuranceOption = approved.insuranceOption
  return {
    id: `${userId}-${categoryId}-approved`,
    userId,
    draft: {
      categoryId,
      category,
      categoryDetails: fixedLicenseType({
        categoryId,
        preferredNumber: displayIssuedNumber(approved).toString(),
        sidecarPartner: approved.sidecarPartner,
        licenseAssociation: todoMigrateAssociation(approved.licenseAssociation),
        licenseType: approved.licenseType,
        ...(insuranceOption ? { insuranceOption } : {}),
        comment: '',
        bikeMake: approvedDetails?.bikeMake || '',
        teamName: approvedDetails?.teamName || '',
        processedAt: approved.draftProcessedAt,
      }),
      summary: draft?.summary,
    },
    approved,
    documents: documents?.[userId] || {},
    type: 'approved',
    association: todoMigrateAssociation(approved.licenseAssociation),
  }
}

function sortDraftsByCategory(filteredDrafts: LicenseDraftWithDocuments[]) {
  return sortBy(filteredDrafts, ({ draft, documents }) =>
    [
      categoryCommonName(draft.category),
      documents?.personalData?.lastName,
      documents?.personalData?.firstName,
    ].join(' ')
  )
}
