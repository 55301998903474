import assertNever from 'assert-never'
import firebase from 'firebase/app'
import { useParams } from 'react-router'
import { LicenseForm } from 'app/license/license-form'
import { Year } from 'shared/data/license-year'

interface NewLicenseProps {
  user: firebase.User
}

export function NewLicense({ user }: NewLicenseProps) {
  const params = useParams<{ year: string | undefined }>()
  const year = params.year ? (parseInt(params.year) as Year) : undefined

  return year === 2021 ? (
    <LicenseForm user={user} year={2021} />
  ) : year === 2022 ? (
    <LicenseForm user={user} year={2022} />
  ) : year === 2023 ? (
    <LicenseForm user={user} year={2023} />
  ) : year === 2024 ? (
    <LicenseForm user={user} year={2024} />
  ) : year === 2025 ? (
    <LicenseForm user={user} year={2025} />
  ) : !year ? null : (
    assertNever(year)
  )
}
